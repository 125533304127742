// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-danke-jsx": () => import("./../src/pages/danke.jsx" /* webpackChunkName: "component---src-pages-danke-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-leistungen-jsx": () => import("./../src/pages/leistungen.jsx" /* webpackChunkName: "component---src-pages-leistungen-jsx" */),
  "component---src-pages-loxone-jsx": () => import("./../src/pages/loxone.jsx" /* webpackChunkName: "component---src-pages-loxone-jsx" */),
  "component---src-pages-news-jsx": () => import("./../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-referenzen-jsx": () => import("./../src/pages/referenzen.jsx" /* webpackChunkName: "component---src-pages-referenzen-jsx" */),
  "component---src-pages-ueber-uns-jsx": () => import("./../src/pages/ueber-uns.jsx" /* webpackChunkName: "component---src-pages-ueber-uns-jsx" */)
}

